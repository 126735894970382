/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CLEAR_MSG,
	DOCUMENT_SUBMITTED,
	EMAIL_PASS,
	ERROR,
	HANDLE_BUSINESS_FIELD_CHANGE,
	HANDLE_CONTACT_FIELD_CHANGE,
	HANDLE_CURRENCY_CHANGE,
	LOG_IN,
	LOG_OUT,
	OFFER_SELECTED,
	OFFER_SELECTION_DONE,
	REMOVE_HASH,
	RESET_STATE,
	SET_LOGIN_DATA,
	SHOPIFY_CONNECT,
	STRIPE_CONNECT,
	UPDATE_CLIENT,
	FACEBOOK_CONNECT,
	UPDATE_VALUE,
	UPDATE_OTP_TIMER,
} from 'store/types';
import { PAGE } from '../../constants';

interface AuthReducerState {
	page: string;
	previousPage: string;
	hashKey: string | undefined;
	passEligibilityTest: boolean | null;
	errMsg: string | null;
	successMsg: string | null;
	contactInfo: {
		firstName: string;
		lastName: string;
		phone_prefix: string;
		phone: string;
		email: string;
		marketingPermission: boolean;
		password: string;
	};
	businessInfo: {
		revMonth: string;
		reqAdvanceAmount: string;
		businessType: string;
		companyName: string;
		regCountry: string;
		website: string;
		currency: string;
	};
	otpTimer: number;
	loginData: {
		isLoggedIn: boolean | null;
		accessToken: string | null;
		clientId: string | null;
		email: string | null;
		currency: string | null;
		passEligibilityTest: boolean | null;
		businessPlatform: {
			STRIPE: boolean | null;
			WOCOMMERCE: boolean | null;
			SHOPIFY: boolean | null;
		};
		marketingPlatform: {
			FACEBOOK: boolean | null;
			GOOGLE: boolean | null;
			GOOGLE_ADS: boolean | null;
		};
		codatPlatform: any;
		// {
		// 	AMAZON_SELLER: boolean | null;
		// 	PAYPAL: boolean | null;
		// 	SQUARE: boolean | null;
		// 	WOO_COMMERCE: boolean | null;
		// 	XERO: boolean | null;
		// }
		currentState: string | null;
		forceStates: string[] | [];
	};
	onBoardingStep: number;
}

let initialState: AuthReducerState = {
	page: PAGE.CONTACT_DETAILS,
	previousPage: PAGE.CONTACT_DETAILS,
	errMsg: null,
	successMsg: null,
	hashKey: undefined,
	passEligibilityTest: null,
	contactInfo: {
		firstName: '',
		lastName: '',
		phone_prefix: '+44',
		phone: '',
		email: '',
		marketingPermission: true,
		password: '',
	},
	businessInfo: {
		revMonth: '0',
		reqAdvanceAmount: '0',
		businessType: '',
		companyName: '',
		regCountry: '',
		website: '',
		currency: 'EUR',
	},
	otpTimer: 0,
	loginData: {
		isLoggedIn: null,
		accessToken: null,
		clientId: null,
		email: null,
		currency: null,
		passEligibilityTest: null,
		businessPlatform: {
			STRIPE: null,
			WOCOMMERCE: null,
			SHOPIFY: null,
		},
		codatPlatform: {
			AMAZON_SELLER: null,
			PAYPAL: true,
			SQUARE: true,
			WOO_COMMERCE: null,
			XERO: true,
		},
		marketingPlatform: {
			FACEBOOK: null,
			GOOGLE: null,
			GOOGLE_ADS: null,
		},
		currentState: null,
		forceStates: null,
	},
	onBoardingStep: 0,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const authReducer = (state = initialState, action: any) => {
	switch (action.type) {
		// Register actions
		case HANDLE_CONTACT_FIELD_CHANGE:
			return {
				...state,
				contactInfo: {
					...state.contactInfo,
					[action.payload.target.name]: action.payload.target.value,
				},
			};

		case HANDLE_BUSINESS_FIELD_CHANGE:
			return {
				...state,
				businessInfo: {
					...state.businessInfo,
					[action.payload.target.name]: action.payload.target.value,
				},
			};

		case UPDATE_VALUE:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};

		case UPDATE_OTP_TIMER:
			return {
				...state,
				otpTimer: action.payload,
				hashKey: state.otpTimer === 0 ? undefined : state.hashKey,
			};

		case HANDLE_CURRENCY_CHANGE:
			return {
				...state,
				businessInfo: {
					...state.businessInfo,
					regCountry: action.payload.country,
					currency: action.payload.currency,
				},
			};

		case RESET_STATE:
			return {
				...state,
				page: PAGE.CONTACT_DETAILS,
				previousPage: PAGE.CONTACT_DETAILS,
				contactInfo: {
					firstName: '',
					lastName: '',
					phone_prefix: '+44',
					phone: '',
					email: '',
					marketingPermission: true,
					password: '',
				},
				businessInfo: {
					revMonth: '0',
					reqAdvanceAmount: '0',
					businessType: '',
					companyName: '',
					regCountry: '',
					website: '',
					currency: 'EUR',
				},
				passEligibilityTest: action.payload,
			};

		case EMAIL_PASS:
			return {
				...state,
				hashKey: action.payload.hashkey,
				loginData: {
					...state.loginData,
					email: action.payload.email,
				},
			};

		case LOG_IN:
			return {
				...state,
				hashKey: undefined,
				otpTimer: 0,
				loginData: {
					...state.loginData,
					...action.payload.client,
					accessToken: action.payload.accessToken,
					isLoggedIn: true,
				},
			};

		case REMOVE_HASH:
			return {
				...state,
				hashKey: undefined,
				otpTimer: 0,
			};

		//Check Login state reducers
		case SET_LOGIN_DATA:
			return {
				...state,
				loginData: {
					...state.loginData,
					...action.payload,
				},
			};

		case LOG_OUT:
			return {
				page: PAGE.CONTACT_DETAILS,
				previousPage: PAGE.CONTACT_DETAILS,
				errMsg: null,
				successMsg: null,
				hashKey: undefined,
				passEligibilityTest: null,
				contactInfo: {
					firstName: '',
					lastName: '',
					phone_prefix: '+44',
					phone: '',
					email: '',
					marketingPermission: true,
					password: '',
				},
				businessInfo: {
					revMonth: '0',
					reqAdvanceAmount: '0',
					businessType: '',
					companyName: '',
					regCountry: '',
					website: '',
					currency: 'EUR',
				},
				otpTimer: 0,
				loginData: {
					isLoggedIn: null,
					accessToken: null,
					clientId: null,
					email: null,
					currency: null,
					passEligibilityTest: null,
					businessPlatform: {
						STRIPE: null,
						WOCOMMERCE: null,
						SHOPIFY: null,
					},
					marketingPlatform: {
						FACEBOOK: null,
						GOOGLE: null,
						GOOGLE_ADS: null,
					},
					currentState: null,
					forceStates: null,
				},
				onBoardingStep: 0,
			};

		case OFFER_SELECTED:
			return {
				...state,
				loginData: {
					...state.loginData,
					chooseOffer: true,
					offerId: action.payload,
				},
			};

		//Connection Reducers actions
		case STRIPE_CONNECT:
			return {
				...state,
				loginData: {
					...state.loginData,
					businessPlatform: {
						...state.loginData.businessPlatform,
						STRIPE: true,
					},
				},
			};

		case SHOPIFY_CONNECT:
			return {
				...state,
				loginData: {
					...state.loginData,
					businessPlatform: {
						...state.loginData.businessPlatform,
						SHOPIFY: true,
					},
				},
			};
		//new
		case FACEBOOK_CONNECT:
			return {
				...state,
				loginData: {
					...state.loginData,
					businessPlatform: {
						...state.loginData.businessPlatform,
						FACEBOOK: true,
					},
				},
			};

		case DOCUMENT_SUBMITTED:
			return {
				...state,
				loginData: {
					...state.loginData,
					documentSubmitted: true,
				},
			};

		case OFFER_SELECTION_DONE:
			return {
				...state,
				loginData: {
					...state.loginData,
					offerSelected: true,
				},
			};

		case UPDATE_CLIENT:
			return {
				...state,
				loginData: {
					...state.loginData,
					currentState: action.payload,
				},
			};

		case ERROR:
			return {
				...state,
				errMsg: action.payload.message,
			};

		case CLEAR_MSG:
			return {
				...state,
				errMsg: null,
				successMsg: null,
			};

		default:
			return state;
	}
};

export const RootState = initialState;
export default authReducer;
