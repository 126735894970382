/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	GET_ALL_ADVANCE,
	GET_CONTRACT,
	SET_LOADING,
	SET_OFFER,
	LOG_OUT,
	CHECK_KYC,
	CHECK_FINANCIAL,
	SET_REPAYMENT_GATEWAY,
	SET_REPAYMENT_GATEWAY_STRING,
} from 'store/types';

interface DashboardReducerState {
	loading: boolean;
	contract: any;
	offer: any;
	advance: any[];
	checkKYC: boolean;
	checkFinancial: boolean;
	repaymentGateway: null | any[];
	repaymentGatewayString: undefined | string;
	repaymentGatewayAdvance: undefined | string;
}

let initialState: DashboardReducerState = {
	loading: false,
	contract: [],
	offer: [],
	advance: [],
	checkKYC: false,
	checkFinancial: false,
	repaymentGateway: null,
	repaymentGatewayString: undefined,
	repaymentGatewayAdvance: undefined,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dashReducer = (state = initialState, action: any) => {
	switch (action.type) {
		// Connect Platform actions
		case SET_LOADING:
			return {
				loading: action.payload,
			};

		case GET_CONTRACT:
			return {
				...state,
				contract: action.payload.data.docs,
			};
		case SET_OFFER:
			return {
				...state,
				offer: action.payload,
			};
		case GET_ALL_ADVANCE:
			return {
				...state,
				advance: action.payload,
			};
		case CHECK_KYC:
			return {
				...state,
				checkKYC: action.payload,
			};
		case CHECK_FINANCIAL:
			return {
				...state,
				checkFinancial: action.payload,
			};
		case SET_REPAYMENT_GATEWAY:
			return {
				...state,
				repaymentGateway: action.payload,
			};
		case SET_REPAYMENT_GATEWAY_STRING:
			return {
				...state,
				repaymentGatewayString: action.payload.gateway,
				repaymentGatewayAdvance: action.payload.advanceId,
			};

		case LOG_OUT:
			return {
				...initialState,
			};

		default:
			return state;
	}
};

export const RootState = initialState;
export default dashReducer;
