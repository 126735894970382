import 'public/css/antd.css';
import 'design/scss/global.scss';

import React, { useEffect } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistedStore, store } from 'store';
import { hotjar } from 'react-hotjar';
import { GOOGLE_TAG_MANAGER, HOTJAR_HJID, HOTJAR_HJSV } from 'config';
import TagManager from 'react-gtm-module';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ValerianClientApp({ Component, pageProps }: AppProps) {
	useEffect(() => {
		hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
		TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER });
	}, []);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistedStore}>
				<Component {...pageProps} />
			</PersistGate>
		</Provider>
	);
}

export default ValerianClientApp;
