// Auth Action Types
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const ELIGIBILITY_TEST_STATUS = 'ELIGIBILITY_TEST_STATUS';
export const PERFORM_ELIGIBILITY_TEST = 'PERFORM_ELIGIBILITY_TEST';
export const HANDLE_CONTACT_FIELD_CHANGE = 'HANDLE_CONTACT_FIELD_CHANGE';
export const HANDLE_BUSINESS_FIELD_CHANGE = 'HANDLE_BUSINESS_FIELD_CHANGE';
export const HANDLE_CURRENCY_CHANGE = 'HANDLE_CURRENCY_CHANGE';
export const HANDLE_REGISTRATION_FIELD_CHANGE =
	'HANDLE_REGISTRATION_FIELD_CHANGE';
export const HANDLE_CHANGE_MARKETING_PERMISSION =
	'HANDLE_CHANGE_MARKETING_PERMISSION';
export const HANDLE_SELECT_CHANGE = 'HANDLE_SELECT_CHANGE';
export const RESET_STATE = 'RESET_STATE';
export const VERIFY_MAIL = 'VERIFY_MAIL';
export const REMOVE_HASH = 'REMOVE_HASH';
export const VERIFY_OTP = 'VERIFY_OTP';
export const EMAIL_EXSISTS = 'EMAIL_EXSISTS';
export const STRIPE_CONNECT = 'STRIPE_CONNECT';
export const CONNECT_ERROR = 'CONNECT_ERROR';
export const OTP_ERROR = 'OTP_ERROR';
export const EMAIL_PASS = 'EMAIL_PASS';
export const EMAIL_PASS_NOT = 'EMAIL_PASS_NOT';
export const NOT_VERIFIED = 'NOT_VERIFIED';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_INFO_STORE = 'LOGIN_INFO_STORE';
export const VERIFY_FAIL = 'VERIFY_FAIL';
export const EMAIL_NOT_AUTHORIZED = 'EMAIL_NOT_AUTHORIZED';
export const OTP_FAIL = 'OTP_FAIL';
export const OTP_FAIL_RESET = 'OTP_FAIL_RESET';
export const LOGIN_PAGE_BACK = 'LOGIN_PAGE_BACK';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const OFFER_SELECTED = 'OFFER_SELECTED';
export const SET_LOADER_ONE = 'SET_LOADER_ONE';
export const SET_LOADER_TWO = 'SET_LOADER_TWO';
export const DOCUMENT_SUBMITTED = 'DOCUMENT_SUBMITTED';
export const RESET_ELIGIBITY = 'RESET_ELIGIBITY';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CONTRACT = 'GET_CONTRACT';
export const SHOPIFY_CONNECT = 'SHOPIFY_CONNECT';
export const SET_OFFERID = 'SET_OFFERID';
export const SET_LOADING = 'SET_LOADING';
export const SET_OFFER = 'SET_OFFER';
export const OFFER_SELECTION_DONE = 'OFFER_SELECTION_DONE';
export const GET_ALL_ADVANCE = 'GET_ALL_ADVANCE';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';

export const ERROR = 'ERROR';

export const CLEAR_MSG = 'CLEAR_MSG';
export const UPDATE_VALUE = 'UPDATE_VALUE';

//Check KYC and Financial states
export const CHECK_KYC = 'CHECK_KYC';
export const CHECK_FINANCIAL = 'CHECK_FINANCIAL';

// Client Action Types
export const CONNECT_STRIPE = 'CONNECT_STRIPE';
export const SAVE_NONCE_FOR_SHOPIFY = 'SAVE_NONCE_FOR_SHOPIFY';
//FB connect
export const FACEBOOK_CONNECT = 'FACEBOOK_CONNECT';
export const GOOGLE_CONNECT = 'GOOGLE_CONNECT';
export const GOOGLEADS_CONNECT = 'GOOGLEADS_CONNECT';

export const UPDATE_OTP_TIMER = 'UPDATE_OTP_TIMER';

export const SET_DEPLOYMENT_KEY = 'SET_DEPLOYMENT_KEY';

export const SET_REPAYMENT_GATEWAY = 'SET_REPAYMENT_GATEWAY';

export const SET_REPAYMENT_GATEWAY_STRING = 'SET_REPAYMENT_GATEWAY_STRING';
