export const ENV = String(process.env.NEXT_PUBLIC_WORK_ENV);
export const BASE_URL = String(process.env.NEXT_PUBLIC_API_BASE_URL);
export const STRIPE_OAUTH_URL = String(
	process.env.NEXT_PUBLIC_STRIPE_OAUTH_URL
);
export const HOTJAR_HJID = parseInt(process.env.NEXT_PUBLIC_HOTJAR_HJID);
export const HOTJAR_HJSV = parseInt(process.env.NEXT_PUBLIC_HOTJAR_HJSV);
export const DEPLOYMENT_KEY = String(process.env.NEXT_PUBLIC_DEPLOYMENT_KEY);
export const GOOGLE_ADS_CLIENT_ID = String(
	process.env.NEXT_PUBLIC_GOOGLE_ADS_CLIENT_ID
);
export const GOOGLE_ADS_REDIRECT_URI = String(
	process.env.NEXT_PUBLIC_GOOGLE_ADS_REDIRECT_URI
);
export const GOOGLE_TAG_MANAGER = String(
	process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER
);
