export const PAGE = {
	CONTACT_DETAILS: 'contact_details',
	BUSINESS_DETAILS: 'business_details',
	CREDENTIAL_DETAILS: 'credential_details',
	THANKS_PAGE: 'thanks_page',
};

export const stepArray = [
	'User Registration',
	'Connect Data Platforms',
	'Upload Financial Data',
	'Verify Identity',
	'Select Offer',
	'Sign Agreement',
	'Setup Repayment',
];
