import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dashReducer from './dashBoardReducer';
import settingsReducer from './settingsReducer';

export default combineReducers({
	settings: settingsReducer,
	auth: authReducer,
	dash: dashReducer,
});
